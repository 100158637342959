const frFr = {
    global: {
        logout: "Déconnexion",
        reason: "Raison",
        identifier: "Identifier",
        confirm: "Confirmation",
        cancel: "Annuler",
        brand: "Marque",
        color: "Couleur",
        vendorArticleNumberShort: "Frn. Art. numéro",
        vendorColorShort: "Frn. de couleur",
        price: "Prix",
        size: "Taille",
        xTypeOrders: (type) => `${type} commandes`,
        amountOrdersFound: (amount, type) => `${amount} ${type} commande(s) trouvée(s)`,
        amountOrdersFoundLast7Days: (amount, type) => `${amount} ${type} commande(s) trouvée(s) dans les 7 derniers jours`,
        created: "Nouveau",
        open: "Nouveau",
        pending: "En traitement",
        picked: "Choisi",
        completed: "Accepté",
        rejected: "Rejeté",
        cancelled: "Annulé",
        undeliverable: "Non livrable",
        delivery: "Livraison",
        sender: "Expéditeur",
        homeDelivery: "Livraison à domicile",
        pickupCode: (code) => `Récupérer (${code})`,
        nameClient: "Nom du client",
        date: "Date",
        xAmountProducts: (amount) => `${amount} produit(s)`,
        view: "Voir les détails",
        processOrder: "Traiter la commande",
        pickOrder: "Commande prise",
        printOrder: "Ordre d'impression",
        acceptAsUndeliverable: "Accepte comme manco",
        recirculate: "Recirculer",
        productsInXOrder: (orderNumber) => `Produit(s) dans la commande&nbsp;<strong>${orderNumber}</strong>`,
        paidPrice: "Prix ​​payé",
        articleNr: "Numéro produit.",
        barcode: "Barcode",
        recirculateRequest: (identifier) => `Recirculer la requête ${identifier}`,
        withoutBranch: "Sans branche",
        withBranch: "Avec branche",
        selectReason: "Sélectionnez une raison",
        selectBranch: "Sélectionnez une branche",
        openProducts: "Produit(s) ouvert(s)",
        pickedProducts: "Produit(s) sélectionné(s)",
        completedProducts: "Produit(s) accepté(s)",
        undeliverableProducts: "Produit(s) manco",
        amountTypeProductsFound: (amount, type) => `${amount} ${type} trouvé`,
        acceptMancoProductConfirm: (barcode, identifier) =>
            `Êtes-vous sûr de vouloir accepter le produit <b>${barcode}</b> dans la requête <b>${identifier}</b> comme manco?`,
        accept: "Accepter",
        reject: "Rejeter",
        orderNr: "N° de commande.",
        recirculateProduct: (barcode) => `Recirculer le produit ${barcode}`,
        rejectProduct: "Rejeter le produit",
        reasonForRejection: (barcode) => `Pour quelle raison voulez-vous rejeter ce produit (${barcode})?`,
        articleNumber: "Numéro de l'article",
        name: "Nom",
        sortBy: "Trier par",
        openPlural: "Ouvert",
        pickedPlural: "Choisi",
        completedPlural: "Accepté",
        unkownPlural: "Inconnue",
        acceptUndeliverableRequest: (identifier) => `Êtes-vous sûr de vouloir accepter la requête ${identifier} en tant que manco?`,
        searchByOrderNr: "Rechercher par numéro de commande",
        search: "Rechercher...",
        unknown: "Inconnue",
        request: (identifier) => `Demande "${identifier}"`,
        orderNumber: "Numéro de commande",
        creationDate: "Date de création",
        searchByBarcode: "Rechercher par code-barres...",
        acceptAll: "Tout accepter",
        acceptRemaining: "Accepter le reste",
        rejectAll: "Tout refuser",
        rejectRemaining: "Refuser restant",
        undoAll: "Défaire tout",
        confirmSelection: "Confirmer la sélection",
        selected: "Choisi",
        noSelectedArticles: "Aucun article sélectionné",
        selectedArticle: (description, size) => `${description}, taille ${size}`,
        selectAReason: "Sélectionnez une raison",
        packstation: "Packstation",
        selectAPackstation: "Sélectionner une station pack",
        submitRequest: "Demande",
        bundledRequestNotice:
            "<strong>Remarque:</strong> Les articles ont été acceptés et refusés. Une demande ne peut être soumise que par lots.<br/><br/>Si un ou plusieurs articles sont refusés, vous devez refuser complètement la demande.",
        bundledRequestNoticePicked:
            "<strong>Remarque:</strong> les éléments ont été acceptés et rejetés. Une demande ne peut être soumise de manière groupée que parce que vous êtes en train de traiter la commande.<br/><br/>Si un ou plusieurs articles ont été refusés, vous devez rejeter complètement la demande, la totalité de la demande doit alors être à nouveau sélectionnée.",
        xTypeProducts: (type) => `${type} producten`,
        xAmountOfxTypeProductsFound: (amount, type) => `${amount} ${type} ${amount === 1 ? "product" : "producten"} gevonden.`,
        structureCode: "code de structure",
        reset: "Réinitialiser",
        itemsFoundXFromX: (amount, total) => `${amount} sur ${total} articles trouvés`,
        pick: "Cueillette",
        resetFilters: "Effacer tout"
    },
    filters: {
        shippingAgents: "Expéditeurs",
        productCount: "Nombre de produits",
        singleProduct: "Un seul produit",
        multipleProducts: "Plusieurs produits"
    },
    navigation: {
        open: "Ouvrir",
        picked: "Choisi",
        completed: "Complété",
        undeliverable: "Non livrables"
    },
    messages: {
        error: {
            somethingWentWrong: "Quelque chose s'est mal passé.",
            somethingWentWrongRecirculation: "Quelque chose s'est mal passé, en conséquence, le produit n'a pas pu être recyclé.",
            somethingWentWrongAcceptMancoProduct: "Quelque chose s'est mal passé, en conséquence, le produit défectueux n'a pu être accepté.",
            somethingWentWrongAcceptProduct: "Quelque chose s'est mal passé, en conséquence, le produit n'a pu être accepté.",
            somethingWentWrongRejectProduct: "Quelque chose s'est mal passé, à cause de cela, le produit n'a pas pu être rejeté.",
            somethingWentWrongAcceptRequest: "Quelque chose s'est mal passé, de ce fait la demande n'a pas pu être acceptée.",
            articleByBarcodeNotFound: (barcode) => `L'article avec le code-barres "${barcode}" n'a plus pu être trouvé.`
        },
        warning: {
            noAccessToUndeliverables: "Page manquante dans le Order Circulatie",
            barcodeNotFound: (barcode) => `Code-barres "${barcode}" introuvable.`,
            isNotCirculationBranch: "Attention : Cet emplacement ne participe pas à la circulation des commandes. Si vous continuez avec cette demande, veuillez traiter la demande en sélectionnant l'emplacement dans cette application."
        },
        success: {
            undeliverableRecirculated: "La demande manquante a été remise en circulation avec succès!",
            undeliverableProductAccepted: "Le produit défectueux a été accepté avec succès.",
            productWasAccepted: (name) => `<strong>${name}</strong> a été accepté avec succès.`,
            productWasRejected: (name, reasonDescription) => `<strong>${name}</strong> a été rejeté avec succès (${reasonDescription}).`,
            undeliverableRequestAccepted: "La demande de manque à gagner a été acceptée avec succès.",
            articleWithBarcodeAccepted: (barcode) => `1 article avec le code-barres "${barcode}" a été accepté.`,
            requestSubmitted: "La demande a été soumise avec succès.",
            productWasRejectedPicked: (name) => `<strong>${name}</strong> a été restauré avec succès dans les produits ouverts.`,
            productWasPicked: (name) => `<strong>${name}</strong> a été sélectionné avec succès.`
        },
        info: {
            noBranchWithPermission:
                "Vous n'êtes pas lié à une agence,<br/>mais vous avez le droit d'imiter une agence.<br/>Pour continuer, choisissez une agence en haut à droite du menu utilisateur<br/>ou connectez-vous avec un compte qui est lié à une branche.",
            noBranchWithoutPermission:
                "Vous n'êtes pas lié à une succursale.<br />Cela signifie que vous ne pouvez pas effectuer de demande de circulation de commande.<br /><br />Pour y accéder, vous devez vous connecter avec un<br />compte lié à un bifurquer.",
            requestWillBePrinted: "La demande est imprimée",
            packstationIsSelected: (packstationCode) => `<strong>${packstationCode}</strong><br/>est sélectionné.`,
            productWillGetOpenStatus: `<strong>Remarque:</strong> L'article aura le statut "ouvert" et devra être à nouveau récupéré dans l'onglet "Ouvert".`,
            acceptedProductsWillGetPickedStatus: `<strong>Remarque:</strong> Les articles acceptés dans cette demande recevront le statut cueillis, continuez à le traiter dans l'onglet Pcueillis.`,
            requestWillGetOpenStatus: `<strong>Remarque:</strong> La demande recevra le statut "ouvert" et devra être reprise dans l'onglet "Ouvert".`,
        },
        validation: {
            selectReason: "Vous devez sélectionner une raison.",
            selectBranch: "Vous devez sélectionner un emplacement."
        },
        loading: {
            default: "Charger...",
            oneMomentPlease: "Un instant s'il vous plaît...",
            xTypeOrdersBeingLoaded: (type) => `${type} les commandes sont en cours de chargement...`,
            productsBeingLoaded: "Les produits sont chargés...",
            requestIsSubmitting: "La demande est soumise..."
        }
    }
};

export default frFr;
