const nlNl = {
    global: {
        logout: "Uitloggen",
        reason: "Reden",
        identifier: "Identifier",
        confirm: "Bevestiging",
        cancel: "Annuleren",
        brand: "Merk",
        color: "Kleur",
        vendorArticleNumberShort: "Artikelnr. lev.",
        vendorColorShort: "Kleur lev.",
        price: "Prijs",
        size: "Maat",
        xTypeOrders: (type) => `${type} orders`,
        amountOrdersFound: (amount, type) => `${amount} ${type} ${amount === 1 ? "order" : "orders"} gevonden`,
        amountOrdersFoundLast7Days: (amount, type) => `${amount} ${type} ${amount === 1 ? "order" : "orders"} gevonden in de laatste 7 dagen`,
        created: "Nieuw",
        open: "Open",
        pending: "In behandeling",
        picked: "Gepickt",
        completed: "Afgehandeld",
        rejected: "Afgewezen",
        cancelled: "Geannuleerd",
        undeliverable: "Manco",
        delivery: "Verzending",
        sender: "Vervoerder",
        homeDelivery: "Thuisbezorgen",
        pickupCode: (code) => `Afhalen (${code})`,
        nameClient: "Naam klant",
        date: "Datum",
        xAmountProducts: (amount) => `${amount} product(en)`,
        view: "Inhoud bekijken",
        processOrder: "Order afhandelen",
        pickOrder: "Order picken",
        printOrder: "Order printen",
        acceptAsUndeliverable: "Accepteren als manco",
        recirculate: "Hercirculeren",
        productsInXOrder: (orderNumber) => `Product(en) in order&nbsp;<strong>${orderNumber}</strong>`,
        paidPrice: "Betaalde prijs",
        articleNr: "Artikelnr.",
        barcode: "Barcode",
        recirculateRequest: (identifier) => `Verzoek ${identifier} hercirculeren`,
        withoutBranch: "Zonder vestiging",
        withBranch: "Met vestiging",
        selectReason: "Selecteer een reden",
        selectBranch: "Selecteer een vestiging",
        openProducts: "Openstaande product(en)",
        pickedProducts: "Gepickte product(en)",
        completedProducts: "Afgehandelde product(en)",
        undeliverableProducts: "Manco product(en)",
        amountTypeProductsFound: (amount, type) => `${amount} ${type} gevonden`,
        acceptMancoProductConfirm: (barcode, identifier) =>
            `Weet u zeker dat u het product <b>${barcode}</b> in het verzoek <b>${identifier}</b> wilt accepteren als een manco?`,
        accept: "Accepteren",
        reject: "Afwijzen",
        orderNr: "Ordernr.",
        recirculateProduct: (barcode) => `Product ${barcode} hercirculeren`,
        rejectProduct: "Product afwijzen",
        reasonForRejection: (barcode) => `Wat is de reden dat u dit product (${barcode}) wilt afwijzen?`,
        articleNumber: "Artikelnummer",
        name: "Naam",
        sortBy: "Sorteer op",
        openPlural: "Openstaande",
        pickedPlural: "Gepickte",
        completedPlural: "Afgehandelde",
        unkownPlural: "Onbekende",
        acceptUndeliverableRequest: (identifier) => `Weet u zeker dat u het verzoek ${identifier} wilt accepteren als een manco?`,
        searchByOrderNr: "Zoek op ordernummer",
        search: "Zoeken...",
        unknown: "Onbekend",
        request: "Verzoek",
        orderNumber: "Ordernummer",
        creationDate: "Aanmaakdatum",
        searchByBarcode: "Zoek op barcode...",
        acceptAll: "Alles accepteren",
        acceptRemaining: "Resterende accepteren",
        rejectAll: "Alles weigeren",
        rejectRemaining: "Resterende weigeren",
        undoAll: "Alles ongedaan maken",
        confirmSelection: "Selectie bevestigen",
        selected: "Geselecteerd",
        noSelectedArticles: "Geen geselecteerde artikelen",
        selectedArticle: (description, size) => `${description}, Maat ${size}`,
        selectAReason: "Selecteer een reden",
        packstation: "Packstation",
        selectAPackstation: "Selecteer een packstation",
        submitRequest: "Verzoek indienen",
        bundledRequestNotice:
            "<strong>Let op:</strong> Er zijn artikelen zowel geaccepteerd als geweigerd. Een verzoek mag alleen gebundeld ingediend worden.<br/><br/>Als er één of meerdere artikelen geweigerd is/zijn, dient u het verzoek compleet te weigeren.",
        bundledRequestNoticePicked:
            "<strong>Let op:</strong> Er zijn artikelen zowel geaccepteerd als geweigerd. Een verzoek mag alleen gebundeld ingediend worden omdat u de order aan het afhandelen bent.<br/><br/>Als er één of meerdere artikelen geweigerd is/zijn, dient u het verzoek compleet te weigeren, het volledige verzoek zal dan opnieuw gepicked moeten worden.",
        xTypeProducts: (type) => `${type} producten`,
        xAmountOfxTypeProductsFound: (amount, type) => `${amount} ${type} ${amount === 1 ? "product" : "producten"} gevonden.`,
        structureCode: "Structuurcode",
        reset: "Herstel",
        itemsFoundXFromX: (amount, total) => `${amount} van ${total} gevonden`,
        pick: "Picken",
        resetFilters: "Verwijder alle"
    },
    filters: {
        shippingAgents: "Vervoerders",
        productCount: "Aantal producten",
        singleProduct: "Eén product",
        multipleProducts: "Meerdere producten"
    },
    navigation: {
        open: "Openstaand",
        picked: "Gepickt",
        completed: "Afgehandeld",
        undeliverable: "Manco"
    },
    messages: {
        error: {
            somethingWentWrong: "Er is iets fout gegaan.",
            somethingWentWrongRecirculation: "Er is iets fout gegaan, hierdoor kon het product niet gehercirculeerd worden.",
            somethingWentWrongAcceptMancoProduct: "Er is iets fout gegaan, hierdoor kon het manco product niet geaccepteerd worden.",
            somethingWentWrongAcceptProduct: "Er is iets fout gegaan, hierdoor kon het product niet geaccepteerd worden.",
            somethingWentWrongRejectProduct: "Er is iets fout gegaan, hierdoor kon het product niet afgewezen worden.",
            somethingWentWrongAcceptRequest: "Er is iets fout gegaan, hierdoor kon het verzoek niet geaccepteerd worden.",
            articleByBarcodeNotFound: (barcode) => `Artikel met barcode "${barcode}" kon niet meer gevonden worden.`
        },
        warning: {
            noAccessToUndeliverables: "Manco's pagina in de Order Circulatie",
            barcodeNotFound: (barcode) => `Barcode "${barcode}" niet gevonden.`,
            isNotCirculationBranch: "Let op: Deze vestiging doet niet mee in de ordercirculatie. Indien je dit verzoek toch doorzet, handel het verzoek dan ook af door de vestiging te selecteren in deze app."
        },
        success: {
            undeliverableRecirculated: "Het manco verzoek is succesvol gehercirculeerd!",
            undeliverableProductAccepted: "Het manco product is succesvol geaccepteerd.",
            productWasAccepted: (name) => `<strong>${name}</strong> is succesvol geaccepteerd.`,
            productWasRejected: (name, reasonDescription) => `<strong>${name}</strong> is succesvol afgewezen (${reasonDescription}).`,
            undeliverableRequestAccepted: "Het manco verzoek is succesvol geaccepteerd.",
            articleWithBarcodeAccepted: (barcode) => `1 artikel met barcode "${barcode}" is geaccepteerd.`,
            requestSubmitted: "Het verzoek is succesvol ingediend!",
            productWasRejectedPicked: (name) => `<strong>${name}</strong> is succesvol teruggezet bij de openstaande producten.`,
            productWasPicked: (name) => `<strong>${name}</strong> is succesvol gepickt.`
        },
        info: {
            noBranchWithPermission:
                "U bent niet gekoppeld aan een vestiging,<br/>maar u heeft wel rechten om een vestiging na te bootsen.<br/>Om door te gaan, kies een vestiging rechtsbovenin in het gebruikersmenu<br/>of log in met een account dat wel gekoppeld is aan een vestiging.",
            noBranchWithoutPermission:
                "U bent niet gekoppeld aan een vestiging.<br />Hierdoor kunt u geen order circulatie verzoek plaatsen.<br /><br />Om toegang te krijgen moet u inloggen met een<br />account dat gekoppeld is aan een vestiging.",
            requestWillBePrinted: "Het verzoek wordt uitgeprint.",
            packstationIsSelected: (packstationCode) => `<strong>${packstationCode}</strong><br/>is geselecteerd.`,
            productWillGetOpenStatus: `<strong>Let op:</strong> Het artikel krijgt de status "open" en moet opnieuw gepicked worden in het "Openstaand" tabblad.`,
            acceptedProductsWillGetPickedStatus: `<strong>Let op:</strong> De ge-accepteerde artikelen in dit verzoek krijgen de status "gepickt", handel deze verder af in het "Gepickt" tabblad.`,
            requestWillGetOpenStatus: `<strong>Let op:</strong> Het verzoek krijgt de status "open" en moet opnieuw gepicked worden in het "Openstaand" tabblad.`
        },
        validation: {
            selectReason: "U dient een reden te selecteren.",
            selectBranch: "U dient een vestiging te selecteren."
        },
        loading: {
            default: "Laden...",
            oneMomentPlease: "Een moment geduld alstublieft...",
            xTypeOrdersBeingLoaded: (type) => `${type} orders worden geladen...`,
            xTypeProductsBeingLoaded: (type) => `${type} producten worden geladen...`,
            requestIsSubmitting: "Verzoek wordt ingediend..."
        }
    }
};

export default nlNl;
